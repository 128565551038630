import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import styles from "./MobileAppSection.module.scss";

import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { camelCaseToSnakeCase } from "@lib/helper/stringUtils";
import { badgeImages, homePageImages } from "@lib/images";

type Topic = "discover" | "look" | "encourage";
type Platform = "appStore" | "googlePlay";

const MobileAppSection: FunctionComponent = () => {
  const { t } = useTranslation(["home", "common"]);

  const _renderTitleContainer = (): JSX.Element => (
    <div className={styles["mobileapp-section__container__features__title-container"]}>
      <h2 className={styles["mobileapp-section__container__features__title-container__title"]}>
        {t("mobile_app.title")}
      </h2>
      <div className={styles["mobileapp-section__container__features__title-container__arrow-container"]}>
        <Image
          src={homePageImages.fancyArrow}
          alt="arrow"
          width={101}
          height={66}
          sizes={createSizesMediaQueries({ smSize: 0, mdSize: 0, xlSize: 10 })}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
    </div>
  );

  const _renderBlock = (topic: Topic): JSX.Element => (
    <div className={styles["mobileapp-section__container__features__block"]}>
      <h3 className={styles["mobileapp-section__container__features__block__title"]}>
        {t(`mobile_app.${topic}.title`)}
      </h3>
      <p className={styles["mobileapp-section__container__features__block__paragraphe"]}>
        {t(`mobile_app.${topic}.paragraphe`)}
      </p>
    </div>
  );

  const _renderStoreBadge = (platform: Platform): JSX.Element => {
    const href = t(`stores.${camelCaseToSnakeCase(platform)}.href`, { ns: "common" });
    const source = badgeImages.fr[platform];
    return (
      <div className={styles["mobileapp-section__container__images__badge"]}>
        <Link href={href} target="popup">
          <Image
            src={source}
            alt={t("mobile_app.links.seo")}
            width={119}
            height={35}
            sizes={createSizesMediaQueries({ smSize: 40, mdSize: 20 })}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Link>
      </div>
    );
  };

  const _renderImagesBlock = (): JSX.Element => (
    <div className={styles["mobileapp-section__container__images"]}>
      <div className={styles["mobileapp-section__container__images__badge-container"]}>
        {_renderStoreBadge("appStore")}
        {_renderStoreBadge("googlePlay")}
      </div>
      <h2 className={styles["mobileapp-section__container__images__mobile-title"]}>{t("mobile_app.title")}</h2>
      <div className={styles["mobileapp-section__container__image-container"]}>
        <Image
          src={homePageImages.mobileAppVisual}
          alt={t("mobile_app.seo")}
          width={1410}
          height={1386}
          sizes={createSizesMediaQueries({ smSize: 100, mdSize: 50 })}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
    </div>
  );

  return (
    <section className={styles["mobileapp-section"]}>
      <div className={styles["mobileapp-section__container"]}>
        {_renderImagesBlock()}
        <div className={styles["mobileapp-section__container__features"]}>
          {_renderTitleContainer()}
          {_renderBlock("discover")}
          {_renderBlock("look")}
          {_renderBlock("encourage")}
        </div>
      </div>
    </section>
  );
};

export default MobileAppSection;
