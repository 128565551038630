import Image from "next/image";
import { useTranslation } from "next-i18next";
import { FunctionComponent } from "react";

import styles from "./HeroSection.module.scss";

import BrandSearchBar from "@components/brand/brandSearchBar";
import ArrowLink from "@components/generic/arrowLink";
import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { getAllBrandPath } from "@lib/helper/pathUtils";
import { commonImages, homePageImages } from "@lib/images";

type ImagePosition = "left" | "right";
const IMAGE_WIDTH = { left: 1029, right: 934 };
const IMAGE_HEIGHT = { left: 571, right: 649 };

const HeroSection: FunctionComponent = () => {
  const { t } = useTranslation("home");

  const _renderImage = (position: ImagePosition): JSX.Element => {
    const leftImageClassName = `${styles["herosection__discover-row__image"]} ${styles["herosection__discover-row__image--left"]}`;
    const rightImageClassName = `${styles["herosection__discover-row__image"]} ${styles["herosection__discover-row__image--right"]}`;
    const className = position === "left" ? leftImageClassName : rightImageClassName;
    const source = position === "left" ? homePageImages.leftVisual : homePageImages.rightVisual;

    return (
      <div className={className}>
        <Image
          src={source}
          alt={t("hero_section.seo")}
          width={IMAGE_WIDTH[position]}
          height={IMAGE_HEIGHT[position]}
          sizes={createSizesMediaQueries({ smSize: 0, mdSize: 40 })}
          style={{
            width: "100%",
            height: "auto",
            marginBottom: "-5px",
          }}
        />
      </div>
    );
  };

  return (
    <section className={styles["herosection"]}>
      <div className={styles["herosection__container"]}>
        <h1 className={styles["herosection__title"]}>{t("hero_section.title")}</h1>
        <p className={styles["herosection__subtitle"]}>{t("hero_section.subtitle")}</p>

        <BrandSearchBar className={styles["herosection__search-bar"]} />

        <ArrowLink href={getAllBrandPath()} type="primary" id={"cta-all-brands-home"}>
          <p className={styles["herosection__arrow-link__text"]}>{t("hero_section.all_brands")}</p>
        </ArrowLink>

        <div className={styles["herosection__discover-row"]}>
          {_renderImage("left")}
          <a href="#presentation-section" className={styles["herosection__discover-row__mid-container"]}>
            <p className={styles["herosection__discover-row__text"]}>{t("hero_section.discover")}</p>
            <div className={styles["herosection__discover-row__down-arrow"]}>
              <Image
                src={commonImages.arrow}
                alt="arrow"
                width={17}
                height={8}
                sizes="5vw"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
          {_renderImage("right")}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
