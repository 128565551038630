import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import styles from "./PresentationSection.module.scss";

import ArrowLink from "@components/generic/arrowLink";
import { createSizesMediaQueries } from "@lib/helper/imageUtils";
import { homePageImages } from "@lib/images";

type Topic = "manifesto" | "methodology";

function PresentationSection() {
  const { t } = useTranslation("home");
  const router = useRouter();
  const methodologySource = router.locale == "fr" ? homePageImages.methodologyFr : homePageImages.methodologyEn;

  const _renderBlock = (topic: Topic): JSX.Element => (
    <div className={styles["presentation-section__text-column__block"]}>
      <h2 className={styles["presentation-section__text-column__block__title"]}>{t(`presentation.${topic}.title`)}</h2>
      <p className={styles["presentation-section__text-column__block__paragraphe"]}>
        {t(`presentation.${topic}.paragraphe`)}
      </p>
      <ArrowLink href={t(`presentation.${topic}.link`)} type="tertiary" shouldPopup={true}>
        <span className={styles["presentation-section__text-column__block__link-text"]}>
          {t(`presentation.${topic}.link_text`)}
        </span>
      </ArrowLink>
    </div>
  );

  return (
    <section className={styles["presentation-section"]} id="presentation-section">
      <div className={styles["presentation-section__image-container"]}>
        <Image
          src={methodologySource}
          alt={t("presentation.seo")}
          width={1704}
          height={1350}
          priority={true}
          sizes={createSizesMediaQueries({ smSize: 100, mdSize: 50, xlSize: 40 })}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className={styles["presentation-section__text-column"]}>
        {_renderBlock("manifesto")}
        {_renderBlock("methodology")}
      </div>
    </section>
  );
}

export default PresentationSection;
