import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";

import HeroSection from "@components/home/heroSection";
import MobileAppSection from "@components/home/mobileAppSection";
import PresentationSection from "@components/home/presentationSection";
import { getCanonicalLink } from "@lib/helper/pathUtils";

export const TRANSLATION_FILES = [
  "common",
  "brand",
  "footer",
  "error",
  "home",
  "custom404",
  "all-brands",
  "header",
  "product",
];

const Home: NextPage = () => {
  const { locale } = useRouter();
  const isFrench = locale == "fr";
  const { t } = useTranslation("home");
  const canonicalLink = getCanonicalLink(locale);

  return (
    <>
      <NextSeo
        title={t("seo.title")}
        description={t("seo.description")}
        openGraph={{
          title: t("seo.title"),
          description: t("seo.description"),
          locale: locale,
        }}
        canonical={canonicalLink}
      />
      <HeroSection />
      <PresentationSection />
      {isFrench && <MobileAppSection />}
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, TRANSLATION_FILES)),
  },
});

export default Home;
